/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 15, 2023 */



@font-face {
    font-family: 'barlowlight';
    src: url('barlow-light-webfont.woff2') format('woff2'),
         url('barlow-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowmedium';
    src: url('barlow-medium-webfont.woff2') format('woff2'),
         url('barlow-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowsemibold';
    src: url('barlow-semibold-webfont.woff2') format('woff2'),
         url('barlow-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowthin';
    src: url('barlow-thin-webfont.woff2') format('woff2'),
         url('barlow-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowlight';
    src: url('barlow-light-webfont.woff2') format('woff2'),
         url('barlow-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowmedium';
    src: url('barlow-medium-webfont.woff2') format('woff2'),
         url('barlow-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowregular';
    src: url('barlow-regular-webfont.woff2') format('woff2'),
         url('barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowsemibold';
    src: url('barlow-semibold-webfont.woff2') format('woff2'),
         url('barlow-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}




@font-face {
    font-family: 'barlowthin';
    src: url('barlow-thin-webfont.woff2') format('woff2'),
         url('barlow-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display:swap;

}
