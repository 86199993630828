
/*** semantic-ui  ***/

html {
  font-size: 14px;
  line-height: 1.15;
}

body {
    line-height: 1.4285em;
}

button, input, optgroup, select, textarea {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

a {
    text-decoration: none;
}

p:last-child {
    margin-bottom: 0;
}

p {
    margin: 0 0 1em;
    line-height: 1.4285em;
}


// Importar sass
// @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');
@import 'assets/fonts/iconos/iconos.css';
@import 'assets/fonts/Barlow/stylesheet.css';
@import 'assets/fonts/Oswald/styleOswald.css';
@import 'css/properties.scss';

html {
    height: auto;
}

body {
    margin: 0;
    font-size: $size-letra-gral;
    background: $color-body 0 0 no-repeat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
    color: #000;
}

body,
button,
textarea,
input,
h1, h2, h3, h4,
select {
    font-family: $font-principal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
::before,
::after,
button,
textarea,
input,
select {
    box-sizing: border-box;
}


a.no-txt {
    position: relative;
}

a.no-txt > span {

    position: absolute;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
}


/****** Search bar ******/


#search-box {
    position: relative;

    input {
        width: 100%;
        line-height: 1.2em;
        padding: .7em 43px .7em 1em;
        border: none;
        outline: none;

        border-radius: 10px;
        border-width: 3px;
        background-color: #fff;
        padding-right: 3em;
        font-family: $font-busqueda;
    }

    input:focus {
        border-color: #85b7d9;
    }

    #search-prompt:after {
        height: 100%;
    }

    #search-prompt::placeholder {
        color: #707070;
    }

    .lupa {
        position: absolute;
        top: 0;
        right: -2px;
        margin: 0;
        display: inline-block;
        height: 100%;
        width: 43px;
        display: flex;
        justify-content: center;
        align-items: center;


        transition: all .5s ease;
        cursor: pointer;
        background-color: #E6E6E6;
        color: #505082;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1;
    }

    .lupa:hover {
        color: #85b7d9;
    }

    #search-prompt-listbox {
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: calc(100% - 20px);
        padding: 1em;
        margin-left: 10px;
        color: #989898;
    }

    .sugg-hl-item {
        background-color: #E6E6E6;
    }

    .typeahead {
        color: #E6E6E6;
    }

    .sugg-match {
        color: #3F3F3F;
    }

    .MuiAutocomplete-clearIndicator {
        display: none;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiOutlinedInput-root {
        padding: 0;
    }


}


/*******  pages ********/

.page {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: none;
        background-color: rgba(0, 0, 0, .28);
    }

    .overlay.open {
        display: block;
    }

    main {
        color: $color;
        padding: 20px 40px;
        min-height: 500px;
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-template-rows: auto 1fr;
        position: relative;
        column-gap: 15px;

        > .titulo:nth-child(2) {
            grid-row: 1 / auto;
            grid-column: 2 / auto;
            align-self: end;
        }

        .titulo {
            font-size: 2em;
        }

        > .contenido {
            grid-row: 2 / auto;
            grid-column: 2 / auto;
            padding: 20px 0;
        }

        .liga-back {
            z-index: 1;
            color: #FFCC99;
            font-size: 1.1em;
            border-bottom: 1px solid rgba(255, 255, 255, .7);
            padding: 10px 0 5px;
            grid-row: 1 / auto;
            grid-column: 1 / auto;
            display: flex;
            align-items: center;
        }

        .liga-back:before {
            margin-right: .5em;
        }

    }

}

.loading {
    width: 100%;

    .loader:after {
        border-color: #FFCC99 transparent transparent;
    }
}

main.error {
    .contenido {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



main.acercade {
    .contenido {
        max-width: 900px;
        text-align: justify;
        font-size: 1.1em;
    }
}


/*******  items ********/

.metadata {

    .key {
        font-weight: bold;
    }

    .key:not(.val):after {
        // content: ':';
    }

    .key:not(:first-child) {
        margin-top: .8em;
    }

    .val + .key {
        margin-top: 0;
    }

    .value {
        overflow-wrap: break-word;
    }
}


.filter,
.emenu {

    position: absolute;
    top: calc(100% + 10px);
    right: 7%;
    background-color: #fff;
    border-radius: 7px;
    padding: 20px 30px;
    z-index: 6;
    font-size: 1.1em;
    min-width: 270px;
    color: #000;

    .filter-title,
    .menu-title {
        border-bottom: 1px solid #333;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding: 0 0 5px;
    }

    .menu-item {
        padding: 8px 0;
        cursor: pointer;
        position: relative;
        display: block;
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: left;
    }

    .menu-item:before {
        position: absolute;
        right: calc(100% + 3px);
        margin-top: 2px;
    }


    // toto esto para que no crezca el width en el hover
    .menu-item:after {
        content: attr(data-text);
        position: absolute;
        left: 0;
    }

    .menu-item > span:last-child {
        opacity: .3;
        float: right;
        margin-left: 1em;
    }

    .menu-item > span:first-child {
        opacity: 0;
        font-weight: bold;
    }

    .menu-item.selected,
    .menu-item:not(:disabled):hover:after {
        font-weight: bold;
    }

    .menu-item:disabled {
        opacity: .3;
    }

}

.filter {
    // position: sticky;
    top: 0;
    left: 0;
    right: unset;
    background-color: rgba(255, 255, 255, .9);
    transition: all ease 300ms;
    max-height: calc(100vh - 132px);
    height: 100%;
    min-height: 468px;

    .filter-title {
        color: #F07A12;
    }

    .btn-toggle {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        right: 0px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
        transition: all ease 300ms;
        background-color: transparent;
    }
}

.filter.closed {
    transform: translateX(-100%);

    .btn-toggle {
        right: -40px;
        background-color: rgba(255, 255, 255, .9);
    }
}

.error-msg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);

    > * {
        margin-top: 3em;
    }
}
