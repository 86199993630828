@import 'css/properties.scss';

#header {
    margin-bottom: 0;

    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 40px;

    align-items: center;
    justify-content: space-between;

    padding: 16px 7% 38px 30px;
    position: relative;

    .logo {
        height: 76px;
        min-width: 76px;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font-logo;

        a {
            height: 100%;
        }

        img {
            height: 100%;
        }

        .title {
            padding-left: .9em;

            :first-child {
                text-align: center;
                color: $color;
                font-size: 2.2em;
                line-height: 1;
            }

            :last-child {
                // margin-top: 5px;
                text-align: center;
                color: $color;
                font-size: 1.18em;
                line-height: 1.2;
            }
        }
    }


    .btn-menu {
        background-color: #fff;
        padding: .35em 1em .3em;
        border-radius: 1em;;
        font-size: 1.2em;
        border: none;
        cursor: pointer;
        align-self: flex-end;
        grid-column: 3 / auto;

        :last-child {
            font-size: .9em;
            margin-left: .5em;
        }
    }

    .search-box {
        max-width: 900px;
        // flex-grow: 2;
        align-self: flex-end;
        justify-self: center;
        width: 100%;
    }
}

#header.head-search {
    border-bottom: 1px solid #8C8CC9;
}

@media screen and (max-width: 970px) {
    #header.head-search  {
        padding-right: 30px;

        .logo {
            height: 60px;
            .title {
                :first-child {
                    font-size: 1.8em;
                }

                :last-child {
                    font-size: .8em;
                    line-height: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #header.head-search  {
        row-gap: 1em;
        padding-bottom: 1em;

        .search-box {
            grid-column: 1 / span 3;
            grid-row: 2 / auto;
        }

        .btn-menu {
            align-self: center;
        }
    }
}

@media screen and (max-width: 830px) {
    #header {
        padding-bottom: 10px;
        .logo {
            height: 60px;
            .title {
                :first-child {
                    font-size: 1.8em;
                }

                :last-child {
                    font-size: .8em;
                    line-height: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {

    #header:not(.head-search) {
        grid-template-columns: 1fr;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        gap: 1em;

        .logo {
            width: fit-content;
            justify-self: center;
        }

        .btn-menu {
            border-radius: 0;
            align-self: stretch;
            grid-column: auto;
            height: 41px;
        }
    }
}

@media screen and (max-width: 550px) {
    #header.head-search {
        .logo {
            .title {
                display: none;
            }
        }
    }

    main.home .item-results .res-tit {
        margin-top: .7em;
    }
}
