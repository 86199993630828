@import 'css/properties.scss';

#footer {
    display: grid;
    grid-template-columns: 5% auto auto 1fr;
    grid-template-rows: auto auto auto 1fr;
    background-color: #fff;

    .logos {
        grid-column: auto / span 4;
        padding: 16px 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #4848A3;
        flex-wrap: wrap;

        img {
            height: 40px;
        }

        img:not(:first-child) {
            margin-left: 2em;
        }

        a {
            display: block;
            height: 100%;
            margin-left: 2em;

            img {
                height: 100%;

            }
        }

    }

    .enlaces {
        padding: 1em 0 0;
        text-align: left;
        grid-column: 2 / auto;
        grid-row: 2 / auto;

        a {
            color: $color-font-logo-top;
            font-weight: bold;
            padding: 0 1em 0 0;
        }

        a:not(:first-child) {
            border-left: 1px solid $color-font-logo-top;
            padding: 0 1em;
        }
    }

    .leyenda {
        grid-column: 2 / auto;
        grid-row: 3 / auto;
        max-width: 905px;
        font-size: .8em;
        line-height: 1.2;
        padding: 0 0 1em;

        .cont {
            background-color: #4848A3;
            color: #fff;
            max-width: max-content;
            line-height: 1.8em;
            padding: 0 .5em;
            margin-bottom: 3px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            // color: #4848A3;

            div {
                &:first-child {
                    font-weight: bold;
                }
                &:not(:last-child) {
                    padding-right: 1em;
                }
            }

        }
    }

    .logo {
        width: 100%;
        height: 100%;
        max-width: 450px;
        align-self: flex-start;
        justify-self: flex-end;

        grid-row: 2 / span 3;
        grid-column: 4 / auto;

        background-image: url('../assets/images/fondo-textura-footer.svg');
        background-position: 0 -20px;
        background-size: 500px calc(100% + 20px);
        background-repeat: no-repeat;

    }
}
@media screen and (max-width: 1380px) {
    #footer {
        .leyenda {
            max-width: 630px;
            padding-right: 5%;
        }
    }
}

@media screen and (max-width: 850px) {
    #footer {
        .leyenda {
            max-width: 450px;
        }
    }
}

@media screen and (max-width: 830px) {
    #footer {
        .logos {
            img {
                height: 30px;
            }
        }
    }
}

@media screen and (max-width: 675px) {
    #footer {
        .logos {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1em;
            justify-items: center;
            align-items: center;

            img:not(:first-child) {
                margin: 0;

            }
        }
    }
}
