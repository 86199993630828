/******* properties ********/

$font-principal:barlowregular,Arial,Helvetica,sans-serif;
$font-logo: 'Oswald-Regular',Arial,Helvetica,sans-serif;
$font-busqueda: Arial,Helvetica,sans-serif;

$size-letra-gral: 16px;
$size-textoContenidos-naranja: .8em;
$size-tituloImgMes: 1.2em;

$color-bg: #fff;
$color: #fff;
$color-main-bg:  #49497d;
$color-body: linear-gradient(180deg, #49497C 0%, #25253E 100%);
$color-font-logo-top: #F07A12;
$color-font-logo-bottom: #933ACD;
$color-letra-pricipal: #F9F9F9;
$color-fondo-principal: #49497C;
$color-letra-secundaria: #000000;
$color-textoContenidos-naranja: #FFB061;
$color-textoImgMes:#FFCCFF;
$color-busqueda-fondo: #f1effd;
$color-busqueda-ico: #b1aae6;
$color-dim: rgba(255, 255, 255, .7);
$color-md-col: #B5B5E2;
$color-viewer: rgba(255, 255, 255, .05);
$color-galeria: #feccff;

