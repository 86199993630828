@import 'css/properties.scss';

main.creditos {
    .contenido {
        display: grid;
        grid-template-columns: repeat(2, minmax(350px, 1fr));

        // justify-items: center;

        // border: 1px dashed #fff;
        .titulo {
            font: normal normal bold 36px/50px barlowregular;
        }

        > div {
            padding: 0 40px;
            // border: 1px dashed #fff;

            > div:not(.titulo) {
                margin: 1em 0;
                span {
                    font-weight: bold;
                    text-shadow: 0px 0px 1px #fff;
                }
            }

        }
    }
}
@media screen and (max-width: 1070px) {

    main.creditos {
        .contenido {
            grid-column: 1/ span 2;
        }

        .liga-back {
            border: none;
            padding-bottom: 15px;
        }

    }

}

@media screen and (max-width: 780px) {
    main.creditos {
        .contenido {
            grid-template-columns: 1fr;

            > div:last-child .titulo {
                margin-top: 1em;
            }
        }
    }
}

@media screen and (max-width: 455px) {
    main.creditos {
        .contenido {
            > div {
                padding: 0;
            }
        }
    }
}
