@import 'css/properties.scss';

main.item {
    $side-width: 250px;
    padding: 20px 40px;
    column-gap: 30px;

    .loading {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
        align-self: center;
    }

    .pane {
        display: grid;
        grid-template-columns: $side-width 1fr;
        grid-template-rows: minmax(calc(100vh - 420px), 1fr) 200px;
        position: relative;
        column-gap: 30px;
        row-gap: 20px;
        grid-row: 2 / auto;
        grid-column: 1 / span 2;

        opacity: 0;
        max-height: 0;
        max-width: 0;
        min-height: 0;
        z-index: -1;
        overflow: hidden;

        .pane-l {
            grid-row: 1 / span 2;
            grid-column: 1 / auto;
            z-index: 1;
            padding-top: 20px;
            overflow: auto;
            max-height: calc(100vh - 200px);
            min-height: 500px;
        }

        .pane-lb {
            grid-row: 2 / auto;
            grid-column: 1 / auto;
        }

        .pane-r {
            grid-row: 1 / span 2;
            grid-column: 2 / auto;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }

    .pane.active{
        max-height: unset;
        max-width: unset;
        min-height: 500px;
        opacity: 1;
        overflow: unset;
        z-index: unset;
    }


    .microscopio {
        .pane-l {
            grid-row: 1 / auto;
            min-height: 200px;
            max-height: calc(100vh - 420px);
        }

        .metadata .btn-toggle {
            display: none;
        }
    }

    .ficha {
        .thumb {
            margin: 20px auto;
            width: fit-content;
            height: fit-content;
            img {
                max-width: 500px;
                max-height: 500px;
            }
        }

        .visor {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 15px;
            padding: 40px 20px;
            justify-items: stretch;
            border: solid 1px $color;

            > div {
                padding: 20px;
                background-color: #5C5C8E;

                > div {
                    padding-bottom: 1em;
                }
            }
        }
    }

    .zonas {
        grid-template-columns: calc($side-width + 15px) 1fr;
        column-gap: 15px;

        .desc {
            padding: 30px 20px;
            overflow: auto;
            padding-right: 1em;
            text-align: left;
            background-color: #F5F5F5;
            color: #6C6C6C;
        }

        .word {
            color: #3131db;
            cursor: pointer;
            font-weight: bold;
        }

        i.word {
            font-size: 1.2em;
        }

        .word:hover {
            opacity: .7;
        }

        .config
        {
            .js-word {
                cursor: pointer;
            }

            .active {
                color: #8cefe2;
            }
        }
    }

    .osd.vzonas {
        .pane-lb {
            transform-origin: 0 0;
            transform: scale(.7);
            grid-row: 1 / auto;
            grid-column: 2 / auto;
            z-index: 2;
            justify-self: flex-start;
            align-self: flex-start;
            margin: 1px 0 0 1px;
        }
    }

    .pane-menu {
        min-height: 2.2em;
        border-color: transparent;
        grid-row: 1 / auto;
        grid-column: 2 / auto;
        display: flex;
        justify-content: flex-start;

        .pane-item {
            background-color: transparent;
            padding: 12px 25px;
            border-color: transparent;
            border-radius: unset !important;
            color: $color;
            border: 1px solid transparent;
            margin-bottom: -1px;
            cursor: pointer;
            min-width: max-content;
        }

        .pane-item:not(:first-child) {
            margin-left: -1px;
        }

        .active.pane-item {
            border-color: $color;
            font-weight: bold;
        }

        .pane-item:disabled {
            color: transparent;
        }
    }
}

#openseadragon {
    background-color: #5C5C8E;
    border: solid 1px #fff;
}

#nav-c {
    background-color: #5C5C8E;
}

@media screen and (max-width: 1070px) {

    main.item {
        .microscopio {
            .metadata {
                position: absolute;
                width: 250px;
                left: 0;
                top: 1em;
                background-color: rgba(73, 73, 124, .9);
                padding: 20px;
                max-height: unset;
                overflow: unset;
                transition: all ease 300ms;

                .btn-toggle {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: 0;
                    right: 0px;
                    border-radius: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    cursor: pointer;
                    transition: all ease 300ms;
                    background-color: transparent;
                    color: #fff;
                }
            }

            .metadata.closed {
                transform: translateX(calc(-100% - 40px));

                .btn-toggle {
                    right: -40px;
                    background-color: rgba(73, 73, 124, .9);
                }
            }
        }

        .liga-back {
            border: none;
            padding-bottom: 15px;
        }

        .ficha {

            .datos {
                display: none;
            }
        }

        .pane-menu {
            grid-row: 2 / auto;
            grid-column: 1 / span 2;
        }

        .pane {
            grid-row: 3 / auto;
            grid-template-rows: minmax(calc(100vh - 500px), 1fr) 200px;
            grid-template-columns: auto 1fr;

            .pane-r {
                grid-column: 1 / span 2;
            }
        }
    }

    main.item .zonas .desc {
        max-height: 150px;
        min-height: 150px;
        grid-column: 1 / span 2;
        background-color: rgba(255, 255, 255, .95);
    }


    #nav-c {
        transform-origin: 100% 100%;
        transform: scale(.5);
        grid-column: 2 / auto;
        grid-row: 2 / auto;
        z-index: 2;
        justify-self: flex-end;
        align-self: flex-end;
        margin: 0 1px 1px 0;
    }
}

@media screen and (max-width: 850px) {
    main.item .ficha .visor {
        padding: 20px;
        grid-template-columns: 1fr;
        row-gap: 15px;
        column-gap: 0;
        .thumb {
            img {
                max-width: 350px;
            }
        }
    }
}

@media screen and (max-width: 550px) {

    main.item {
        padding: 20px 10px;
        .microscopio  .metadata.closed {
            transform: translateX(calc(-100% - 10px));
        }
        .pane-menu .pane-item {
            padding: 12px;
        }

        .ficha .visor {
            padding: 20px 15px;
            > div {
                padding: 20px 10px;
            }
            .thumb {
                img {
                    max-width: 270px;
                }
            }

        }
    }


    #nav-c {
        transform: scale(.4);
    }
}
