/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Oswald-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Oswald-Regular'), url('Oswald-Regular.woff') format('woff');
    font-display:swap;
    }
    
