@import 'css/properties.scss';

main.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    .ratio {
        position: sticky;
        top: 0;
        align-self: flex-end;
        margin-right: 1em;
        padding: 0 .5em;
        z-index: 1;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, .5);
    }

    .item-intro {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }

    .item-results {
        width: 100%;
        height: 100%;

        padding: 20px;
        padding-left: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        position: relative;
        margin-top: -1.4em;
        transition: padding-left ease 200ms;


        .item-card:not(.big) {
            margin: 20px 10px;
            font-size: .9em;
        }

        .tema-tit {
            text-align: center;
            width: 100%;
            text-transform: uppercase;
        }

        .res-tit {
            text-align: center;
            width: 100%;

            > :first-child {
                font-size: 2rem;
            }

            > :last-child {
                line-height: 1.5;
                padding: 10px;
                font-size: 1rem;
            }
        }

        .liga-back {
            position: absolute;
            border-bottom: none;
            left: 60px;
            transition: padding-left ease 200ms;
        }
    }

    .item-results.with-filter {
        padding-left: 290px;

        .liga-back {
            left: 290px;
        }

    }
}


.item-card {
    display: grid;
    grid-template-columns: min-content minmax(0, 260px);
    grid-template-rows: auto auto 1fr;
    padding: 0;
    align-items: start;
    column-gap: 10px;

    .metadata {
        grid-row: 1 / span 3;
        grid-column: 2 / auto;
        align-self: stretch;

        padding: 3px 10px;
        border-left: solid 1px rgba(255, 255, 255, .4);
        position: relative;

        .back {
            max-height: 100%;
            transition: max-height 1s linear;
            overflow: hidden;
        }

        .back.hidden {
            max-height: 100px;
        }

        .flip {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .thumb {
        border-radius: 50%;
        justify-self: center;
        padding: 7px;
        // border: dashed 1px #fff;
        transition: all .5s ease;
        background-image: url('../assets/images/circulo.svg');
        background-position: center center;
        background-size: 100% 100%;
        width: 170px;
        height: 170px;

        a {
            display: block;
            border-radius: 50%;
            border: solid 1px rgba(255, 255, 255, .7);
            padding: 2.5%;
            transition: all .5s ease;
            overflow: hidden;
            transform-origin: 50% 50%;
            // position: relative;
            width: 100%;
            height: 100%;

            img {
                transform-origin: 50% 50%;
                border-radius: 50%;
                transition: all .5s ease;
                width: 100%;
                height: 100%;
            }
        }
    }

    .thumb,
    .thumb.right {
        --thumb-deg: 30deg;
    }

    .thumb.left {
        --thumb-deg: -30deg;
    }

    .thumb.right,
    .thumb.left {
        transform-origin: 50% 50%;
        transform: rotate(var(--thumb-deg));

        a {
            transform: rotate(calc(-1 * var(--thumb-deg)));
        }
    }

    .thumb:hover {
        --deg: calc(-1 * var(--thumb-deg));
        transform-origin: 50% 50%;
        transform: rotate(var(--deg));

        a {
            transform: rotate(calc(-1 * var(--deg)));

            img {
                transform: scale(1.05);
            }


        }
    }

    .title {
        padding: 10px;
        text-align: center;
        font-weight: bold;
    }
}


.item-card.big {
    grid-template-columns: min-content minmax(0, 160px) minmax(0, 160px) auto;
    column-gap: 0;
    margin: 0;
    width: 100%;
    max-width: 1350px;
    padding: 0 40px 20px;

    .c-left {
        padding: 10px 20px;
        color: $color-galeria;

        .thumb {
            width: 270px;
            height: 270px;
            margin: 20px 0;
            padding: 12px;
        }

        .tag {
            font-size: $size-tituloImgMes;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            padding: 15px;
        }

        .title {
            padding: 15px;
        }
    }

    .dummy {
        min-height: 550px;
        max-width: 140px;
        // border: dashed 1px #fff;
    }

    .dummy.line {
        border-right: solid 1px #fff;
    }



    .c-right {
        color: $color;
        padding: 10px 20px;
        max-width: 720px;
        justify-self: flex-end;

        .texto {
            text-align: justify;
            font-size: 1.1em;
            border-top: solid 1px rgba(255, 255, 255, .4);
            padding-top: 10px;
            margin-top: 10px;
        }

        .main-title {
            font-size: 2.7em;
            font-weight: bolder;
            line-height: 1;
        }

        .logo {

            a {
                display: block;

                img {
                    height: 60px;

                }
            }

        }

        .search-box {
            min-width: 500px;
            width: 100%;
            margin: 55px 0;

            .lupa {
                background-color: #49497C !important;
                color: #fff !important;
            }
        }
    }

}


@media screen and (max-width: 930px) {
    .item-card.big {
        grid-template-columns: 1fr;
        padding: 0 0 20px;

        .c-right {
            grid-row: 1 / auto;
            justify-self: center;
            padding: 10px 40px;

            .search-box {
                min-width: unset;
                max-width: 600px;
                margin: 55px auto;
            }

            .main-title {
                text-align: center;
            }

            .logo {
                display: flex;
                justify-content: center;
            }

        }
        .c-left {
            grid-row: 2 / auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 40px;
        }

        .dummy {
            display: none;
        }
    }
}

@media screen and (max-width: 830px) {
    .item-card.big {
        padding-top: 2em;
    }
}

@media screen and (max-width: 768px) {
    main.home {
        .item-results.with-filter {
            padding-left: 30px;

            .liga-back {
                left: 60px;
            }

        }
    }
}

@media screen and (max-width: 560px) {
    main.home {
        .item-results {
            .liga-back {
                left: 30px;
                margin-top: 30px;

                span {
                    position: absolute;
                    display: block;
                    overflow: hidden;
                    width: 1px;
                    height: 1px;
                    clip: rect(0, 0, 0, 0);
                }
            }

        }
    }

    .item-card {
        grid-template-columns: minmax(0, 260px);

        .title {
            grid-row: 2 / auto;
            grid-column: auto;
        }

        .thumb {
            grid-row: 1 / auto;
            grid-column: auto;
        }

        .metadata {
            grid-row: 3 / auto;
            grid-column: auto;
            border-top: 1px solid #fff;
            border-left: none;
        }

    }

    .item-card.big {
        grid-template-columns: 1fr;

        .c-right {

            .main-title {
                font-size: 1.7em;
            }
        }

        .c-left {
            overflow: hidden
        }
    }
}


