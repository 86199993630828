/* Generated by grunt-webfont */
/* Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css */
/* Basada en node_modules/grunt-webfont/tasks/templates/bootstrap.css */


@font-face {
    font-family:"iconos";
    src:url("iconos.eot?3f26ef2d792af705fee9045360eefe49");
    src:url("iconos.eot?#iefix") format("embedded-opentype"),
		url("iconos.woff2?3f26ef2d792af705fee9045360eefe49") format("woff2"),
		url("iconos.woff?3f26ef2d792af705fee9045360eefe49") format("woff"),
		url("iconos.ttf?3f26ef2d792af705fee9045360eefe49") format("truetype"),
		url("iconos.svg?3f26ef2d792af705fee9045360eefe49#iconos") format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}


/* Bootstrap Overrides */

.ico-notxt,
.ico,
[class^="ico-"]:before,
[class*=" ico-"]:before
{
    font-family: "iconos";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    text-align: center;
    line-height: 1;
    /* vertical-align: middle; */
    display: inline-block;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.ico span,
[class^="ico-"] span,
[class*=" ico-"] span
{
    vertical-align: middle;
}

.ico-notxt span {
    display: none;
}

.ico[data-ico]:before {
    content: attr(data-ico);
} 

/* Icons */

.ico-abrir:before {
    content:"\f1bf";
} 
.ico-abrir2:before {
    content:"\f1c0";
} 
.ico-acerca:before {
    content:"\f182";
} 
.ico-actualizar:before {
    content:"\f1c1";
} 
.ico-actualizar2:before {
    content:"\f1c2";
} 
.ico-add-user:before {
    content:"\f1b5";
} 
.ico-add-user2:before {
    content:"\f1c3";
} 
.ico-agrega:before {
    content:"\f162";
} 
.ico-agregar:before {
    content:"\f1c4";
} 
.ico-agregar1:before {
    content:"\f187";
} 
.ico-agregar3:before {
    content:"\f1c5";
} 
.ico-agregar4:before {
    content:"\f1c6";
} 
.ico-arrastrar:before {
    content:"\f17f";
} 
.ico-arrastrar1:before {
    content:"\f180";
} 
.ico-arte:before {
    content:"\f106";
} 
.ico-audio:before {
    content:"\f107";
} 
.ico-audio2:before {
    content:"\f19f";
} 
.ico-aviso:before {
    content:"\f1c7";
} 
.ico-ayuda1:before {
    content:"\f189";
} 
.ico-basurero:before {
    content:"\f1be";
} 
.ico-basurero2:before {
    content:"\f1c8";
} 
.ico-biografia:before {
    content:"\f108";
} 
.ico-biologia:before {
    content:"\f109";
} 
.ico-boton:before {
    content:"\f169";
} 
.ico-caleman:before {
    content:"\f153";
} 
.ico-calendar:before {
    content:"\f198";
} 
.ico-candado:before {
    content:"\f16a";
} 
.ico-ccarta:before {
    content:"\f164";
} 
.ico-cfrances:before {
    content:"\f154";
} 
.ico-cglosario:before {
    content:"\f155";
} 
.ico-chome:before {
    content:"\f156";
} 
.ico-cingles:before {
    content:"\f157";
} 
.ico-cinstruccion:before {
    content:"\f163";
} 
.ico-citaliano:before {
    content:"\f158";
} 
.ico-cjour:before {
    content:"\f165";
} 
.ico-cjuego:before {
    content:"\f159";
} 
.ico-cjuego2:before {
    content:"\f15a";
} 
.ico-clic:before {
    content:"\f16b";
} 
.ico-cluna:before {
    content:"\f166";
} 
.ico-comentario:before {
    content:"\f1b7";
} 
.ico-compartir:before {
    content:"\f10a";
} 
.ico-consultar:before {
    content:"\f1b8";
} 
.ico-contenedor:before {
    content:"\f16c";
} 
.ico-contenedor1:before {
    content:"\f181";
} 
.ico-copiar:before {
    content:"\f16d";
} 
.ico-cortar:before {
    content:"\f16e";
} 
.ico-cproceso:before {
    content:"\f15b";
} 
.ico-crear-doc:before {
    content:"\f1b9";
} 
.ico-crear-doc2:before {
    content:"\f1c9";
} 
.ico-creative:before {
    content:"\f10b";
} 
.ico-creative2:before {
    content:"\f10c";
} 
.ico-creditos:before {
    content:"\f10d";
} 
.ico-csol:before {
    content:"\f167";
} 
.ico-cver:before {
    content:"\f168";
} 
.ico-del-user:before {
    content:"\f1b6";
} 
.ico-del-user2:before {
    content:"\f1ca";
} 
.ico-descarga-pdf:before {
    content:"\f1cb";
} 
.ico-descarga:before {
    content:"\f1cc";
} 
.ico-descargar-archivo:before {
    content:"\f1cd";
} 
.ico-deshacer:before {
    content:"\f16f";
} 
.ico-editar:before {
    content:"\f1ba";
} 
.ico-eliminar1:before {
    content:"\f188";
} 
.ico-enviar:before {
    content:"\f10e";
} 
.ico-etica:before {
    content:"\f10f";
} 
.ico-etimologias:before {
    content:"\f110";
} 
.ico-evalua:before {
    content:"\f161";
} 
.ico-evalua1:before {
    content:"\f196";
} 
.ico-exportar:before {
    content:"\f1ce";
} 
.ico-f1e:before {
    content:"\f13b";
} 
.ico-f1n:before {
    content:"\f13c";
} 
.ico-f1s:before {
    content:"\f13e";
} 
.ico-f1w:before {
    content:"\f13d";
} 
.ico-f2e:before {
    content:"\f143";
} 
.ico-f2n:before {
    content:"\f144";
} 
.ico-f2ne:before {
    content:"\f147";
} 
.ico-f2nw:before {
    content:"\f148";
} 
.ico-f2s:before {
    content:"\f146";
} 
.ico-f2se:before {
    content:"\f149";
} 
.ico-f2sw:before {
    content:"\f14a";
} 
.ico-f2w:before {
    content:"\f145";
} 
.ico-f3e:before {
    content:"\f14b";
} 
.ico-f3n:before {
    content:"\f14c";
} 
.ico-f3ne:before {
    content:"\f14f";
} 
.ico-f3nw:before {
    content:"\f150";
} 
.ico-f3s:before {
    content:"\f14e";
} 
.ico-f3se:before {
    content:"\f151";
} 
.ico-f3sw:before {
    content:"\f152";
} 
.ico-f3w:before {
    content:"\f14d";
} 
.ico-facebook:before {
    content:"\f199";
} 
.ico-firma1:before {
    content:"\f1da";
} 
.ico-firma2:before {
    content:"\f1db";
} 
.ico-firma3:before {
    content:"\f1dc";
} 
.ico-firma4:before {
    content:"\f1dd";
} 
.ico-fisica:before {
    content:"\f111";
} 
.ico-flecha-l:before {
    content:"\f1cf";
} 
.ico-flecha-l2:before {
    content:"\f1d0";
} 
.ico-flecha-r:before {
    content:"\f1d1";
} 
.ico-flecha-r2:before {
    content:"\f1d2";
} 
.ico-folder:before {
    content:"\f170";
} 
.ico-fontSize:before {
    content:"\f197";
} 
.ico-geografia:before {
    content:"\f112";
} 
.ico-goma:before {
    content:"\f113";
} 
.ico-guardar:before {
    content:"\f1d3";
} 
.ico-guardar2:before {
    content:"\f1d4";
} 
.ico-historia:before {
    content:"\f114";
} 
.ico-home:before {
    content:"\f115";
} 
.ico-imagen:before {
    content:"\f171";
} 
.ico-imagen2:before {
    content:"\f193";
} 
.ico-imagen3:before {
    content:"\f194";
} 
.ico-imagen4:before {
    content:"\f195";
} 
.ico-imagen5:before {
    content:"\f1a0";
} 
.ico-importar:before {
    content:"\f1d5";
} 
.ico-informacion:before {
    content:"\f116";
} 
.ico-ingles:before {
    content:"\f117";
} 
.ico-instagram:before {
    content:"\f19a";
} 
.ico-lengua:before {
    content:"\f118";
} 
.ico-listen:before {
    content:"\f119";
} 
.ico-logica:before {
    content:"\f11a";
} 
.ico-mas:before {
    content:"\f105";
} 
.ico-matematicas:before {
    content:"\f11b";
} 
.ico-menu:before {
    content:"\f11c";
} 
.ico-music:before {
    content:"\f15c";
} 
.ico-musica:before {
    content:"\f172";
} 
.ico-notificacion:before {
    content:"\f1bb";
} 
.ico-objeto:before {
    content:"\f173";
} 
.ico-ocultar:before {
    content:"\f17d";
} 
.ico-ocultar0:before {
    content:"\f18a";
} 
.ico-ocultar1:before {
    content:"\f18b";
} 
.ico-ocultar3:before {
    content:"\f18d";
} 
.ico-ocultar4:before {
    content:"\f1bc";
} 
.ico-ojo:before {
    content:"\f174";
} 
.ico-opciones:before {
    content:"\f186";
} 
.ico-opciones1:before {
    content:"\f185";
} 
.ico-pantalla:before {
    content:"\f175";
} 
.ico-pausa:before {
    content:"\f11d";
} 
.ico-pegar:before {
    content:"\f176";
} 
.ico-play:before {
    content:"\f11e";
} 
.ico-por:before {
    content:"\f13a";
} 
.ico-preview:before {
    content:"\f177";
} 
.ico-propiedades:before {
    content:"\f183";
} 
.ico-propiedades1:before {
    content:"\f184";
} 
.ico-propiedades2:before {
    content:"\f18f";
} 
.ico-propiedades3:before {
    content:"\f190";
} 
.ico-propiedades4:before {
    content:"\f191";
} 
.ico-propiedades5:before {
    content:"\f192";
} 
.ico-quimica:before {
    content:"\f11f";
} 
.ico-quimica2:before {
    content:"\f1a2";
} 
.ico-reading:before {
    content:"\f120";
} 
.ico-reading2:before {
    content:"\f121";
} 
.ico-registrar:before {
    content:"\f1d6";
} 
.ico-registrar2:before {
    content:"\f1d7";
} 
.ico-registrar3:before {
    content:"\f1d8";
} 
.ico-rehacer:before {
    content:"\f178";
} 
.ico-reiniciar:before {
    content:"\f122";
} 
.ico-salir:before {
    content:"\f15f";
} 
.ico-salir2:before {
    content:"\f160";
} 
.ico-salir3:before {
    content:"\f17e";
} 
.ico-save:before {
    content:"\f15d";
} 
.ico-save1:before {
    content:"\f179";
} 
.ico-saveb:before {
    content:"\f15e";
} 
.ico-sbiografia:before {
    content:"\f138";
} 
.ico-sbiologia:before {
    content:"\f124";
} 
.ico-scalculadora:before {
    content:"\f125";
} 
.ico-scalculo:before {
    content:"\f126";
} 
.ico-screditos:before {
    content:"\f127";
} 
.ico-sespanol:before {
    content:"\f128";
} 
.ico-sestadistica:before {
    content:"\f129";
} 
.ico-sfisica1:before {
    content:"\f12a";
} 
.ico-sfisica2:before {
    content:"\f12b";
} 
.ico-sformulario:before {
    content:"\f12c";
} 
.ico-sfuentes:before {
    content:"\f12d";
} 
.ico-sgeometria:before {
    content:"\f12e";
} 
.ico-sglosario:before {
    content:"\f139";
} 
.ico-shmexico:before {
    content:"\f130";
} 
.ico-shuniversal:before {
    content:"\f12f";
} 
.ico-sin-comentario:before {
    content:"\f1bd";
} 
.ico-slecturas:before {
    content:"\f131";
} 
.ico-slineatiempo:before {
    content:"\f132";
} 
.ico-smatematicas:before {
    content:"\f133";
} 
.ico-smedia:before {
    content:"\f134";
} 
.ico-spotify:before {
    content:"\f1b3";
} 
.ico-squimica:before {
    content:"\f135";
} 
.ico-stablaperiodica:before {
    content:"\f136";
} 
.ico-stools:before {
    content:"\f137";
} 
.ico-stop:before {
    content:"\f123";
} 
.ico-stop2:before {
    content:"\f18e";
} 
.ico-tap:before {
    content:"\f17a";
} 
.ico-texto:before {
    content:"\f17b";
} 
.ico-tiktok:before {
    content:"\f1b4";
} 
.ico-twitter:before {
    content:"\f19e";
} 
.ico-unam:before {
    content:"\f101";
} 
.ico-ver:before {
    content:"\f1d9";
} 
.ico-video:before {
    content:"\f17c";
} 
.ico-video2:before {
    content:"\f1a1";
} 
.ico-vimeo:before {
    content:"\f19d";
} 
.ico-virtlab:before {
    content:"\f1ae";
} 
.ico-whatsapp:before {
    content:"\f19b";
} 
.ico-youtube:before {
    content:"\f19c";
} 

/* Ejemplo de clase personalizada agregada en glyphs del html */
/*
 * .ico-der1:before {
 *     content: "\F102";
 * }
 */

